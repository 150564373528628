<template>
  <list-container-widget
    id="framevr"
    :title="widgetName"
    :app-id="111"
    stretch
    class="d-flex"
  >
    <iframe v-if="framevrUrl" :src="framevrUrl" style="border:none;height: 100%; border-radius: 5px;" allow="camera;microphone;display-capture;xr-spatial-tracking"></iframe>
    <div v-else class="horizontal-placeholder d-flex flex-column flex-wrap justify-content-center align-items-center">
      <img :src="placeholder">
      <p class="text-primary">
        {{ $t('available.message-singular', { itemName: $t('integrations.framevr') }) }}
      </p>
    </div>
  </list-container-widget>
</template>

<script>

import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';

export default {
  name: 'Framevr',
  components: { ListContainerWidget },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  computed: {
    framevrUrl() {
      if (this.item?.customizations != null ) {
        if (this.item?.customizations['Framevr-link'] != ""){
          return this.item?.customizations['Framevr-link'];
        }
      }
      return null;
    },
    item() {
      return this.$store.getters.item('integrations', 'framevr');
    },
    placeholder() {
      return Placeholder;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        customName: 'integrations',
        requestConfig: {
          orderByDate: -1,
          type: 'integrations',
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
